import api from "./api";
import config from "./config";

const cardsList = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.cards);
};

const cardsAdd = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.cards, body);
};

const cardsAddStep2 = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.patch(config.cards, body);
};

const userCards = (AppKey, token, cards) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(`${config.userCards}${cards}`);
};

const cardInfo = (AppKey, token, cardToken) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.cardInfo, {cardData:cardToken});
};

const cardEdit = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.put(config.cards, body);
};

const cardPhone = (AppKey, token, phone) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(`${config.cards}/${phone}`);
};

const cardDelete = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.delete(config.cards, body);
};

const cardsTheme = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.cardsTheme);
};

const deactivate = (AppKey, token, card) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.delete(config.cardDeactivate, card);
};

export default {
  cardsList,
  cardsAdd,
  cardsAddStep2,
  userCards,
  cardInfo,
  cardEdit,
  cardDelete,
  cardPhone,
  cardsTheme,
  deactivate
};
