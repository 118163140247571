<template>
  <div
    class="modal-overlay"
    :class="{ open: this.$store.state.interface.modals.clarification }"
    @click="closeModal"
  >
    <div
      class="modal-window"
      :class="{ open: this.$store.state.interface.modals.clarification }"
      role="dialog"
      :tabindex="this.$store.state.interface.modals.clarification ? '0' : '-1'"
      :ariaHidden="
        this.$store.state.interface.modals.clarification ? 'false' : 'true'
      "
      @click.stop
      aria-label="Настройки продукта"
    >
      <header class="modal-window__header">
        <h2 class="modal-window__title">Удаление карты</h2>
        <button
          class="modal-window-closed"
          type="button"
          data-modal-close="confirm-logout"
          @click="closeModal"
        >
          <svg width="20" height="20">
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </header>
      <div class="modal-window__content">
        <p class="modal-window__text">
          Вы действительно хотите удалить карту?
        </p>
      </div>
      <div class="modal-window__buttons">
        <button
          class="btn btn--red"
          type="button"
          data-modal-close="confirm-logout"
          @click="deleteCard"
        >
          <span>Да</span>
        </button>
        <button
          class="btn btn--black"
          type="button"
          data-modal-close="confirm-logout"
          @click="closeModal"
        >
          <span>Нет</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import cards from "@/api/cards";
import { mapActions } from "vuex";

export default {
  name: "g-clarification-modal",
  components: {},
  props: {},
  data() {
    return {
      token: "",
      cardListStore: this.$store.state.interface.modals.cardList,
    };
  },
  computed: {},
  methods: {
    ...mapActions(["GET_CARDS"]),
    closeModal() {
      this.$store.state.interface.modals.clarification = false;
      this.$store.state.interface.modals.productModal = true;
    },
    deleteCard() {
      let cardToken = this.$store.state.interface.productModalInfo.id
      cards
        .cardDelete(
          localStorage.getItem("appKey"),
          localStorage.getItem("token"),
          {data: {cardToken: cardToken}}
        )
        .then(() => {
          this.GET_CARDS().then((cards) => {
            this.cardListStore.sender = cards.find(card=> card.is_main === true);
          });
          this.$store.state.interface.modals.clarification = false;
        })
    },
  },
  watch: {},
  mounted() {
  },
  updated() {
    this.token = this.$store.state.interface.productModalInfo.id;
    if (this.$store.state.interface.modals.clarification) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  },
};
</script>
