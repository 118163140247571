<template>
  <div
    class="modal-overlay"
    role="dialog"
    id="transactions-modal"
    tabindex="0"
    :class="{
      open: this.$store.state.interface.modals.transactionModal.status,
    }"
    @click="closeModal"
  >
    <div
      class="modal-window"
      :class="{
        open: this.$store.state.interface.modals.transactionModal.status,
      }"
      role="dialog"
      :tabindex="
        this.$store.state.interface.modals.transactionModal.status ? '0' : '-1'
      "
      :ariaHidden="
        this.$store.state.interface.modals.transactionModal.status
          ? 'false'
          : 'true'
      "
      @click.stop
      aria-hidden="true"
      aria-label="Детали транзакции"
    >
      <header class="modal-window__header">
        <h2 class="modal-window__title">Детали транзакции</h2>
        <button class="modal-window-closed" type="button" @click="closeModal">
          <svg width="20" height="20">
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </header>
      <div
        class="modal-window__content"
      >
        <div class="modal-info" style="justify-content: center">
          <span class="modal-info__img">
            <img
              v-if="transaction.status == 'COMPLETE'"
              src="@/assets/img/icon-check-circle.svg"
            />
            <img
              v-else-if="transaction.sender_type == 'SENDER'"
              :src="transaction.receiver_card_icon"
            />
            <img
              v-else-if="transaction.sender_type == 'RECEIVER'"
              :src="transaction.sender_card_icon"
            />
          </span>
        </div>
        <div class="modal-details">
          <ul class="modal-details__list">
            <li class="modal-details__item" v-if="transaction.type">
              <span class="modal-details__title">Сервис</span>
              <span class="modal-details__description">{{ transaction.type }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.terminal_id">
              <span class="modal-details__title">ID транзакции</span>
              <span class="modal-details__description">{{ transaction.terminal_id }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.sender_card_holder">
              <span class="modal-details__title">Отправитель</span>
              <span class="modal-details__description">{{ transaction.sender_card_holder }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.sender_bank_name">
              <span class="modal-details__title">Банк отправителя</span>
              <span class="modal-details__description">{{ transaction.sender_bank_name }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.sender_card_mask_number">
              <span class="modal-details__title">Карта отправителя</span>
              <span class="modal-details__description">{{ transaction.sender_card_mask_number }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.receiver_card_holder">
              <span class="modal-details__title">Получатель</span>
              <span class="modal-details__description">{{ transaction.receiver_card_holder }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.receiver_bank_name">
              <span class="modal-details__title">Банк получателя</span>
              <span class="modal-details__description">{{ transaction.receiver_bank_name }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.receiver_card_mask_number">
              <span class="modal-details__title">Карта получателя</span>
              <span class="modal-details__description">{{ transaction.receiver_card_mask_number }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.fee_percent">
              <span class="modal-details__title">Комиссия</span>
              <span class="modal-details__description">{{ transaction.fee_percent }}%</span>
            </li>
            <li class="modal-details__item" v-if="transaction.fee_amount && transaction.fee_percent != '0'">
              <span class="modal-details__title">Сумма комиссии</span>
              <span class="modal-details__description">{{ (Number(transaction.fee_amount) / 100).toLocaleString() }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.sender_amount">
              <span class="modal-details__title">Сумма платежа</span>
              <span class="modal-details__description">{{ (Number(transaction.sender_amount) / 100).toLocaleString() }}</span>
            </li>
            <li class="modal-details__item" v-else-if="transaction.hold_at">
              <span class="modal-details__title">Сумма платежа</span>
              <span class="modal-details__description">{{ ((Number(transaction.full_amount) + Number(transaction.fee_amount)) / 100).toLocaleString() }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.date && transaction.time">
              <span class="modal-details__title">Дата и время транзакции</span>
              <span class="modal-details__description">{{ transaction.date }} {{ transaction.time }}</span>
            </li>
            <li class="modal-details__item" v-else-if="transaction.hold_at">
              <span class="modal-details__title">Дата и время транзакции</span>
              <span class="modal-details__description">{{ transaction.hold_at }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.ofd_id_check">
              <span class="modal-details__title">ID фискального чека</span>
              <span class="modal-details__description">{{ transaction.ofd_id_check }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.ofd_sign">
              <span class="modal-details__title">Фискальный признак</span>
              <span class="modal-details__description">{{ transaction.ofd_sign }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.ofd_terminal">
              <span class="modal-details__title">Терминал</span>
              <span class="modal-details__description">{{ transaction.ofd_terminal }}</span>
            </li>
            <li class="modal-details__item" v-if="transaction.ofd_status">
              <span class="modal-details__title">Статус фискализации</span>
              <span class="modal-details__description">{{ transaction.ofd_status }}</span>
            </li>
          </ul>
        </div>
        <footer class="modal-window__footer">
          <button
            class="modal-btn"
            type="button"
            v-if="transaction.type == 'P2P'"
            @click="saveTransaction(transaction)"
          >
            <span class="modal-btn__icon">
              <svg width="20" height="20">
                <use xlink:href="#icon-star"></use>
              </svg>
            </span>
            <span class="modal-btn__text">Сохранить</span>
          </button>
          <button class="modal-btn" type="button" data-soon="Скоро" v-else>
            <span class="modal-btn__icon">
              <svg width="20" height="20">
                <use xlink:href="#icon-star"></use>
              </svg>
            </span>
            <span class="modal-btn__text">Сохранить</span>
          </button>
          <span class="modal-btn" href="./payment-inner.html" data-soon="Скоро">
            <span class="modal-btn__icon">
              <svg width="20" height="20">
                <use xlink:href="#icon-reverse"></use>
              </svg>
            </span>
            <span class="modal-btn__text">Повторить платеж</span>
          </span>
          <button class="modal-btn" type="button" data-soon="Скоро">
            <span class="modal-btn__icon">
              <svg width="20" height="20">
                <use xlink:href="#icon-download"></use>
              </svg>
            </span>
            <span class="modal-btn__text">Скачать</span>
          </button>
          <button
            class="modal-btn"
            type="button"
            data-modal-open="invoice"
            data-soon="Скоро"
          >
            <span class="modal-btn__icon">
              <svg width="20" height="20">
                <use xlink:href="#icon-check"></use>
              </svg>
            </span>
            <span class="modal-btn__text">Квитанция по операции</span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import transfers from "@/api/transfers";
import { mapActions } from "vuex";

export default {
  name: "g-transactions-modal",
  components: {},
  props: {},
  data() {
    return {
      transaction: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions(["GET_SAVEDPRODUCTS"]),
    closeModal() {
      this.$store.state.interface.modals.transactionModal.status = false;
    },
    priceFormatting(sum, type) {
      if (type == "consumption") {
        return `-${Number(sum).toLocaleString()}`;
      } else {
        return `+${Number(sum).toLocaleString()}`;
      }
    },
    cardsNumber(number) {
      let num = number.split("");
      for (let i = 0; i < num.length; i++) {
        if (i >= 6 && i <= num.length - 5) {
          num[i] = "*";
        }
      }
      let size = 4;
      let subarray = [];
      for (let i = 0; i < Math.ceil(num.length / size); i++) {
        subarray[i] = num.slice(i * size, i * size + size).join("");
      }
      return subarray.join(" ");
    },
    dateFormatting(date) {
      let dateFormat = date.split("T").join(" - ");
      return dateFormat;
    },
    saveTransaction(item) {
      transfers
        .favorite(
          localStorage.getItem("appKey"),
          localStorage.getItem("token"),
          {
            name: item.receiver_card_mask_number,
            transaction_id: item.transaction_uuid,
          }
        )
        .then(() => {
          this.GET_SAVEDPRODUCTS();
          this.$store.state.interface.modals.serverError.status = true;
          this.$store.state.interface.modals.serverError.info =
            "Платеж успешно сохранен";
          this.$store.state.interface.modals.serverError.title = "Сохранение";
        })
        .catch((error) => {
          this.$store.state.interface.modals.serverError.status = true;
          this.$store.state.interface.modals.serverError.info =
            error.response.data.message;
          this.$store.state.interface.modals.serverError.title = "Ошибка";
        });
    },
  },
  watch: {},
  mounted() {},
  updated() {
    this.transaction =
      this.$store.state.interface.modals.transactionModal.transaction;
    if (this.$store.state.interface.modals.transactionModal.status) {
      document.body.classList.add("modal-open");
      document.querySelector("#transactions-modal").focus()
    } else {
      document.body.classList.remove("modal-open");
    }
  },
};
</script>
