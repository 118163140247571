import api from "./api";
import config from "./config";

const categoriesList = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.transfers);
};

const provider = (AppKey, token, category, page, perPage = 15) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(`${config.paynetProvider}${category}&page=${page}&perPage=${perPage}` );
};

const serviceInfo = (AppKey, token, service) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.serviceInfo + service);
};

const pay = (AppKey, token, service, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(`${config.pay}${service}/pay`, body);
};

const favoriteAdd = (AppKey, token, service, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(`${config.pay}${service}/favorite`, body);
};

const favoriteDelete = (AppKey, token, service) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.delete(`${config.favorites}/${service}`);
};

const favorites = (AppKey, token, type) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  let typeString = type ? `?type=${type}` : ''
  return api.get(`${config.favorites}` + typeString);
};

export default {
  categoriesList,
  provider,
  serviceInfo,
  pay,
  favoriteAdd,
  favoriteDelete,
  favorites
};
