<template>
  <li
    class="accordion-list__item"
  >
    <button class="accordion-card" type="button" @click="openCardModal()">
      <div class="card-miniature">
        <div class="card-miniature__background">
          <img
            :src="card_data.theme.image"
            :alt="card_data.theme.name"
          />
        </div>
        <div class="card-miniature__header">
          <span class="card-miniature__title">{{ card_data.name }}</span>
        </div>
        <!-- <span class="card-miniature__balance"
          >{{ card_data.balance.toLocaleString() }} <span>UZS</span></span
        > -->
        <div class="card-miniature__footer">
          <span class="card-miniature__number">**{{ lastNumbers(card_data.pan) }}</span>
          <span class="card-miniature__date">{{
            expireFormat(card_data.expire)
          }}</span>
        </div>
      </div>
      <div class="accordion-card__content">
        <span class="accordion-card__title"
          >{{ card_data.name }} **{{ lastNumbers(card_data.pan) }}</span
        >
        <span class="accordion-card__balance"
          >{{ card_balance ? card_data.balance.toLocaleString() : '****' }}
          <span>UZS</span></span
        >
      </div>
    </button>
  </li>
</template>

<script>
export default {
  name: "g-bar-cards",
  components: {},
  props: {
    card_data: {
      type: Object,
      default() {
        return {};
      },
    },
    card_balance: {
      type: Boolean,
      default() {
        return true;
      },
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    lastNumbers(number) {
      let result = String(number).slice(-4);
      return result;
    },
    expireFormat(expire) {
      let array = expire.split("");
      array.splice(2, 0, "/");
      return array.join("");
    },
    openCardModal() {
      this.$store.state.interface.productModalInfo.type = "cards";
      this.$store.state.interface.productModalInfo.id = this.card_data.token;
      this.$store.state.interface.modals.productModal = true;
    },
  },
  watch: {},
  mounted() {},
};
</script>
