<template>
  <div
    class="modal-overlay"
    role="dialog"
    id="exit-modal"
    tabindex="0"
    :class="{ open: this.$store.state.interface.modals.exit }"
    @click="closeModal"
  >
    <div
      class="modal-window"
      :class="{ open: this.$store.state.interface.modals.exit }"
      role="dialog"
      :tabindex="this.$store.state.interface.modals.exit ? '0' : '-1'"
      :ariaHidden="
        this.$store.state.interface.modals.exit ? 'false' : 'true'
      "
      @click.stop
      aria-label="Настройки продукта"
    >
      <header class="modal-window__header">
        <h2 class="modal-window__title">Выход из аккаунта</h2>
        <button
          class="modal-window-closed"
          type="button"
          data-modal-close="confirm-logout"
          @click="closeModal"
        >
          <svg width="20" height="20">
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </header>
      <div class="modal-window__content">
        <p class="modal-window__text">
          Вы уверены что хотите выйти из аккаунта? <br />Возможно вы забыли
          что-то оплатить или может вы хотели совершить перевод?
        </p>
      </div>
      <div class="modal-window__buttons">
        <button
          class="btn btn--red"
          type="button"
          data-modal-close="confirm-logout"
          @click="exit"
        >
          <span>Да</span>
        </button>
        <button
          class="btn btn--black"
          type="button"
          data-modal-close="confirm-logout"
          @click="closeModal"
        >
          <span>Нет</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "g-error",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$store.state.interface.modals.exit = false;
    },
    exit() {
      localStorage.removeItem(`token`);
      localStorage.removeItem(`appKey`);
      window.location.pathname = "/";
    },
  },
  watch: {},
  mounted() {},
  updated() {
    if (this.$store.state.interface.modals.exit) {
      document.body.classList.add("modal-open");
      document.querySelector("#exit-modal [data-modal-close]").focus();
    } else {
      document.body.classList.remove("modal-open");
    }
  },
};
</script>
