import api from "./api";
import config from "./config";

const story = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.story);
};

export default story;
