<template>
    <li class="accordion-list__item">
        <button class="accordion-card" type="button"
            @click="openDepositModal()"
        >
            <div class="deposit-miniature">
                <span>{{card_data.percent}}%</span>
            </div>
            <div class="accordion-card__content">
                <span class="accordion-card__title">{{card_data.depName}}</span>
                <span class="accordion-card__balance">{{card_data.amount.toLocaleString()}} <span>UZS</span></span>
                <span class="accordion-card__percent">
                    <svg width="20" height="20" aria-hidden="true">
                        <use xlink:href="#icon-percent"></use>
                    </svg>
                    <span class="accordion-card__balance">{{card_data.persSum.toLocaleString()}} <span>UZS</span></span>
                </span>
            </div>
        </button>
    </li>
</template>
<script>
export default {
    name: 'g-bar-cards',
    components: {},
    props: {
        card_data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    },
    computed: {
    },
    methods: {
        openDepositModal() {
            this.$store.state.interface.productModalInfo.type = "deposits";
            this.$store.state.interface.productModalInfo.id = this.card_data.depId;
            this.$store.state.interface.modals.productModal = true;
        }
    },
    watch: {},
    mounted() {}
}
</script>