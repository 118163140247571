<template>
    <div
      class="modal-overlay"
      role="dialog"
      id="error-modal"
      tabindex="0"
      :class="{ open: this.$store.state.interface.modals.serverError.status }"
      @click="closeModal"
    >
      <div
        class="modal-window"
        :class="{ open: this.$store.state.interface.modals.serverError.status }"
        role="dialog"
        :tabindex="this.$store.state.interface.modals.serverError.status ? '0' : '-1'"
        :ariaHidden="
          this.$store.state.interface.modals.serverError.status ? 'false' : 'true'
        "
        @click.stop
        aria-label="Настройки продукта"
      >
        <header class="modal-window__header">
          <h2 class="modal-window__title">{{ $store.state.interface.modals.serverError.title }}</h2>
          <button
            class="modal-window-closed"
            type="button"
            data-modal-close="confirm-logout"
            @click="closeModal"
          >
            <svg width="20" height="20">
              <use xlink:href="#icon-close"></use>
            </svg>
          </button>
        </header>
        <div class="modal-window__content">
          <p class="modal-window__text">{{ $store.state.interface.modals.serverError.info }}</p>
        </div>
        <div class="modal-window__buttons">
            <a
            v-if="$store.state.interface.modals.serverError.button.link"
            class="btn btn--red"
            :href="$store.state.interface.modals.serverError.button.link"
          >
            <span>{{ $store.state.interface.modals.serverError.button.title }}</span>
            </a>
          <button
            class="btn btn--black"
            type="button"
            data-modal-close="confirm-logout"
            @click="closeModal"
          >
            <span>ОК</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  <script>
  
  export default {
    name: "g-error",
    components: {},
    props: {},
    data() {
      return {};
    },
    computed: {},
    methods: {
      closeModal() {
        this.$store.state.interface.modals.serverError.status = false;
      },
    },
    watch: {},
    mounted() {
    },
    updated() {
      if (this.$store.state.interface.modals.serverError.status) {
        document.body.classList.add("modal-open");
        document.querySelector("#error-modal [data-modal-close]").focus()
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  };
  </script>
  