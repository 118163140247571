<template>
    <div 
        class="modal-overlay" 
        :class="{ 'open': this.$store.state.interface.modals.notification }" 
        @click="closeModal"
    >
        <div 
            class="modal" 
            :class="{ 'open': this.$store.state.interface.modals.notification }" 
            role="dialog"
            :tabindex="this.$store.state.interface.modals.notification ? '0' : '-1'"
            :ariaHidden="this.$store.state.interface.modals.notification ? 'false' : 'true'" 
            @click.stop aria-hidden="true"
            aria-label="Меню уведомлений"
        >
            <header class="modal-header">
                <div class="modal-header__content">
                    <svg width="20" height="20">
                        <use xlink:href="#icon-bell"></use>
                    </svg>
                    <h2>Уведомления</h2>
                </div>
                <button 
                    class="modal-closed" 
                    type="button" 
                    data-modal-close="notification"
                    @click="closeModal"
                >
                    <svg width="20" height="20">
                        <use xlink:href="#icon-close"></use>
                    </svg>
                </button>
            </header>
            <div class="modal-tabs">
                <ul class="modal-tabs-buttons">
                    <li class="modal-tabs-buttons__item">
                        <button class="active" type="button">Уведомления</button>
                    </li>
                    <li class="modal-tabs-buttons__item">
                        <button type="button">Счета</button>
                    </li>
                </ul>
            </div>
            <div class="modal-tabs-content">
                <div class="modal-tabs-content__inner active">
                    <div class="notifications">
                        <ul class="notifications__list">
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Запрос средств</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <div class="notifications__img  notifications__img--img">
                                        <img src="@/assets/img/user-sm.jpg" alt="Sergey Kim">
                                    </div>
                                    <p>Пользователь запрашивает денежные средства</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./transfers.html" class="notifications__btn">Перевести деньги</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Полезная информация</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <p>Операции по HUMO временно недоступны. Идут технические работы на стороне поставщика
                                        услуг.</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./news-inner.html" class="notifications__btn">Открыть</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Запрос средств</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <div class="notifications__img notifications__img--img">
                                        <img src="@/assets/img/user-sm.jpg" alt="Sergey Kim">
                                    </div>
                                    <p>Пользователь запрашивает денежные средства</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./transfers.html" class="notifications__btn">Перевести деньги</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Полезная информация</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <p>Операции по HUMO временно недоступны. Идут технические работы на стороне поставщика
                                        услуг.</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./news-inner.html" class="notifications__btn">Открыть</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-tabs-content__inner">
                    <div class="notifications">
                        <ul class="notifications__list">
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Получен перевод</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <div class="notifications__img">
                                        <span>S</span>
                                    </div>
                                    <p>Получен перевод средств от пользователя <b>Sergey Kim</b> на сумму <span
                                            class="green">300.000 сум</span>.</p>
                                </div>
                                <div class="notifications__footer">
                                    <button class="notifications__btn" type="button"
                                        data-modal-open="transaction-details">Открыть чек</button>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Запрос средств</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <div class="notifications__img  notifications__img--img">
                                        <img src="@/assets/img/user-sm.jpg" alt="Sergey Kim">
                                    </div>
                                    <p>Пользователь запрашивает денежные средства</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./transfers.html" class="notifications__btn">Перевести деньги</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Полезная информация</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <p>Операции по HUMO временно недоступны. Идут технические работы на стороне поставщика
                                        услуг.</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./news-inner.html" class="notifications__btn">Открыть</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Получен перевод</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <div class="notifications__img">
                                        <span>S</span>
                                    </div>
                                    <p>Получен перевод средств от пользователя <b>Sergey Kim</b> на сумму <span
                                            class="green">300.000 сум</span>.</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./transfers.html" class="notifications__btn">Открыть чек</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Запрос средств</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <div class="notifications__img notifications__img--img">
                                        <img src="@/assets/img/user-sm.jpg" alt="Sergey Kim">
                                    </div>
                                    <p>Пользователь запрашивает денежные средства</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./transfers.html" class="notifications__btn">Перевести деньги</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                            <li class="notifications__block">
                                <div class="notifications__header">
                                    <h3 class="notifications__title">Полезная информация</h3>
                                    <span class="notifications__date">15.05.2023</span>
                                </div>
                                <div class="notifications__content">
                                    <p>Операции по HUMO временно недоступны. Идут технические работы на стороне поставщика
                                        услуг.</p>
                                </div>
                                <div class="notifications__footer">
                                    <a href="./news-inner.html" class="notifications__btn">Открыть</a>
                                    <button class="notifications__link" type="button">Отметить как прочитанное</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'g-notification-modal',
    components: {
    },
    props: {},
    data() {
        return {

        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.$store.state.interface.modals.notification = false;
        }
    },
    watch: {},
    updated() { 
        if (this.$store.state.interface.modals.notification) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    }
}
</script>