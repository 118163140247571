<template>
    <div class="modal-overlay" :class="{ 'open': this.$store.state.interface.modals.productModal }" @click="closeModal">
        <div class="modal-window" :class="{ 'open': this.$store.state.interface.modals.productModal }" role="dialog"
            :tabindex="this.$store.state.interface.modals.productModal ? '0' : '-1'"
            :ariaHidden="this.$store.state.interface.modals.productModal ? 'false' : 'true'" 
            @click.stop
            aria-label="Настройки продукта">
            <g-bar-product-card
                v-if="this.$store.state.interface.productModalInfo.type == 'cards'"
            />
            <g-bar-product-deposits
                v-if="this.$store.state.interface.productModalInfo.type == 'deposits'"
            />
            <g-bar-product-credits
                v-if="this.$store.state.interface.productModalInfo.type == 'credits'"
            />
            <g-bar-product-wallets
                v-if="this.$store.state.interface.productModalInfo.type == 'wallets'"
            />
            <g-bar-product-accounts
                v-if="this.$store.state.interface.productModalInfo.type == 'accounts'"
            />
        </div>
    </div>
</template>
<script>
import gBarProductCard from '@/components/modals/product-modal-content/g-bar-product-card.vue'
import gBarProductDeposits from '@/components/modals/product-modal-content/g-bar-product-deposits.vue'
import gBarProductCredits from '@/components/modals/product-modal-content/g-bar-product-credits.vue'
import gBarProductWallets from '@/components/modals/product-modal-content/g-bar-product-wallets.vue'
import gBarProductAccounts from '@/components/modals/product-modal-content/g-bar-product-accounts.vue'

export default {
    name: 'g-bar-product-modal',
    components: {
        gBarProductCard,
        gBarProductDeposits,
        gBarProductCredits,
        gBarProductWallets,
        gBarProductAccounts
    },
    props: {},
    data() {
        return {
            
        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.$store.state.interface.modals.productModal = false;
        }
    },
    watch: {},
    updated() {
        if (this.$store.state.interface.modals.productModal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    }
}
</script>