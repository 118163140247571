import api from "./api";
import config from "./config";

const update = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  return api.post(config.firebaseUpdate, { firebase_token: token });
};

const firebaseConfig = {
  apiKey: "AIzaSyBfjyKIdHyESaxlUVMTBKHg-rJrGBH0IEk",
  authDomain: "garantbank-3a105.firebaseapp.com",
  projectId: "garantbank-3a105",
  storageBucket: "garantbank-3a105.appspot.com",
  messagingSenderId: "331365447128",
  appId: "1:331365447128:web:deab9bcf47f35c9678aead",
};

export default { firebaseConfig, update };
