<template>
  <g-sprite />
  <g-wrapper v-if="$store.state.user.token && APPKEY" />
  <g-auth v-else-if="isWidth" />
  <div class="not-format">
    <img class="not-format__img" src="@/assets/img/1.png" alt="Мобильное приложение" />
    <h2 class="not-format__title">
      Мобильное приложение <br />
      для iOS и Android
    </h2>
    <p class="not-format__text">
      Все доступные операции банка в вашем смартфоне
    </p>
    <footer class="not-format__footer">
      <a href="/" class="btn btn--black" aria-label="Скачать для IOS">
        <svg width="20" height="20">
          <use xlink:href="#icon-apple"></use>
        </svg>
      </a>
      <a href="/" class="btn btn--black" aria-label="Скачать для Android">
        <svg width="20" height="20">
          <use xlink:href="#icon-google-play"></use>
        </svg>
      </a>
    </footer>
  </div>
</template>

<script>
import gWrapper from "./components/g-wrapper.vue";
import gAuth from "@/pages/g-auth.vue";
import gSprite from "./components/includes/g-sprite.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    gSprite,
    gWrapper,
    gAuth,
  },
  computed: {
    ...mapGetters(["APPKEY"]),
  },
  methods: {
    ...mapActions(["GET_APPKEY"]),
    isWidth() {
      return window.matchMedia(`(min-width: 1100px)`).matches;
    }
  },
  beforeCreate() {
    if (!localStorage.getItem("token")) {
      if (window.location.pathname != "/") {
        window.location.pathname = "/";
      }
    }
  },
  created() {
    this.GET_APPKEY();
  },
  mounted() {
    document.addEventListener("keyup", (event) => {
      if (event.key == "Escape") {
        for (let key in this.$store.state.interface.modals) {
          if (this.$store.state.interface.modals[key] === true) {
            this.$store.state.interface.modals[key] = false;
          } else if (this.$store.state.interface.modals[key].status === true) {
            this.$store.state.interface.modals[key].status = false;
          }
        }
      }
    });
  },
};
</script>
