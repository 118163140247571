import api from "./api";
import config from "./config";

const info = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.p2pInfo, body);
};

const create = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.p2pCreate, body);
};

const confirm = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.p2pConfirm, body);
};

const recentlyRecipients = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.recentlyRecipients);
};

const favorite = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.p2pFavorite, body);
};

export default {
  info,
  create,
  confirm,
  recentlyRecipients,
  favorite
};
