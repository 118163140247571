<template>
    <div class="modal-overlay" :class="{ 'open': this.$store.state.interface.modals.userWidgets }" @click="closeModal">
        <div class="modal-window" :class="{ 'open': this.$store.state.interface.modals.userWidgets }" role="dialog"
            :tabindex="this.$store.state.interface.modals.userWidgets ? '0' : '-1'"
            :ariaHidden="this.$store.state.interface.modals.userWidgets ? 'false' : 'true'" @click.stop
            aria-label="Настройки виджета">
            <header class="modal-window__header">
                <h2 class="modal-window__title">Виджеты</h2>
                <button class="modal-window-closed" type="button" @click="closeModal">
                    <svg width="20" height="20">
                        <use xlink:href="#icon-close"></use>
                    </svg>
                </button>
            </header>
            <div class="modal-window__content">
                <div class="product-widgets">
                    <div class="product-widgets__content">
                        <div class="product-widgets__block">
                            <h3 class="product-widgets__title">Активные</h3>
                            <ul class="product-widgets__list" id="sortable">
                                <li class="product-widgets__item" v-if="cards == true">
                                    <svg width="20" height="20">
                                        <use xlink:href="#icon-drag"></use>
                                    </svg>
                                    <span>Карты</span>
                                    <button class="product-widgets__button" type="button" aria-label="Удалить виджет" @click="removeProduct('cards')">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-trash"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item" v-if="deposits == true">
                                    <svg width="20" height="20">
                                        <use xlink:href="#icon-drag"></use>
                                    </svg>
                                    <span>Вклады</span>
                                    <button class="product-widgets__button" type="button" aria-label="Удалить виджет" @click="removeProduct('deposits')">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-trash"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item" v-if="credits == true">
                                    <svg width="20" height="20">
                                        <use xlink:href="#icon-drag"></use>
                                    </svg>
                                    <span>Кредиты</span>
                                    <button class="product-widgets__button" type="button" aria-label="Удалить виджет" @click="removeProduct('credits')">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-trash"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item" v-if="wallets == true">
                                    <svg width="20" height="20">
                                        <use xlink:href="#icon-drag"></use>
                                    </svg>
                                    <span>Кошельки</span>
                                    <button class="product-widgets__button" type="button" aria-label="Удалить виджет" @click="removeProduct('wallets')">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-trash"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item" v-if="accounts == true">
                                    <svg width="20" height="20">
                                        <use xlink:href="#icon-drag"></use>
                                    </svg>
                                    <span>Счета</span>
                                    <button class="product-widgets__button" type="button" aria-label="Удалить виджет" @click="removeProduct('accounts')">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-trash"></use>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="product-widgets__block">
                            <h3 class="product-widgets__title">Доступные</h3>
                            <ul class="product-widgets__list">
                                <li class="product-widgets__item product-widgets__item--add" v-if="cards == false" @click="addProduct('cards')">
                                    <span>Карты</span>
                                    <button class="product-widgets__button" type="button" aria-label="Добавить виджет">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-plus"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item product-widgets__item--add" v-if="deposits == false" @click="addProduct('deposits')">
                                    <span>Вклады</span>
                                    <button class="product-widgets__button" type="button" aria-label="Добавить виджет">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-plus"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item product-widgets__item--add" v-if="credits == false" @click="addProduct('credits')">
                                    <span>Кредиты</span>
                                    <button class="product-widgets__button" type="button" aria-label="Добавить виджет">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-plus"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item product-widgets__item--add" v-if="wallets == false" @click="addProduct('wallets')">
                                    <span>Кошельки</span>
                                    <button class="product-widgets__button" type="button" aria-label="Добавить виджет">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-plus"></use>
                                        </svg>
                                    </button>
                                </li>
                                <li class="product-widgets__item product-widgets__item--add" v-if="accounts == false" @click="addProduct('accounts')">
                                    <span>Счета</span>
                                    <button class="product-widgets__button" type="button" aria-label="Добавить виджет">
                                        <svg width="20" height="20">
                                            <use xlink:href="#icon-plus"></use>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button class="btn btn--red" type="button">
                        <span>Готово</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'g-user-widgets',
    components: {
    },
    props: {},
    data() {
        return {
            cards: true,
            deposits: true,
            credits: true,
            wallets: false,
            accounts: false,
        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.$store.state.interface.modals.userWidgets = false;
        },
        addProduct(product) {
            this[product] = true
        },
        removeProduct(product) {
            this[product] = false
        }
    },
    watch: {},
    updated() {
        if (this.$store.state.interface.modals.userWidgets) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    }
}
</script>