<template>
  <div class="wrapper">
    <g-header />
    <g-modals-index />
    <main class="main" data-page="home">
      <g-bar v-if="$store.state.interface.activePage != 'showcase'"/>
      <RouterView />
    </main>
  </div>
</template>

<script>
import gBar from "@/components/g-bar.vue";
import gHeader from "@/components/g-header.vue";
import gModalsIndex from "@/components/modals/g-modals-index.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "g-wrapper",
  components: {
    gBar,
    gHeader,
    gModalsIndex,
  },
  props: {},
  data() {
    return {
      title: "g-wrapper",
    };
  },
  computed: {
    ...mapGetters(["INFO", "CARDS", "OPENDEPOSITS"]),
  },
  methods: {
    ...mapActions(["GET_INFO", "GET_CARDS", "GET_OPENDEPOSITS"]),
  },
  watch: {},
  created() {
    this.GET_INFO()
    this.GET_OPENDEPOSITS()
    this.GET_CARDS().then((cards)=> {
      if (cards) {
        this.$store.state.interface.modals.cardList.sender = cards.find(card=> card.is_main === true) ? cards.find(card=> card.is_main === true) : cards[0]
        // this.$store.state.user.info.balance = cards.total_balance
      }
    });
  },
  mounted() {},
};
</script>
