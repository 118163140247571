// import axios from 'axios';
import paynet from "@/api/paynet";
import story from "@/api/story";

const systemStore = {
  state: {
    infoProducts: [],
    // transfers: [],
    payments: [],
    // bankInfo: [],
    // bankProducts: [],
    // transferTypes: [],
    // paymentTypes: [],
    // systemInfo: [],
    // deposits: [],
    // credits: []
  },
  mutations: {
    SET_INFOPRODUCTS: (state, products) => {
      state.infoProducts = products;
    },
    // SET_TRANSFERS: (state, products) => {
    //     state.transfers = products;
    // },
    SET_PAYMENTS: (state, products) => {
      state.payments = products;
    },
    // SET_BANKINFO: (state, products) => {
    //     state.bankInfo = products;
    // },
    // SET_BANKPRODUCTS: (state, products) => {
    //     state.bankProducts = products;
    // },
    // SET_TRANSFERTYPES: (state, products) => {
    //     state.transferTypes = products;
    // },
    // SET_PAYMENTTYPES: (state, products) => {
    //     state.paymentTypes = products;
    // },
    // SET_SYSTEMINFO: (state, products) => {
    //     state.systemInfo = products;
    // },
    // SET_DEPOSITS: (state, products) => {
    //     state.deposits = products;
    // },
    // SET_CREDITS: (state, products) => {
    //     state.credits = products;
    // }
  },
  actions: {
    async GET_INFOPRODUCTS({ commit }) {
      return story(
        localStorage.getItem("appKey"),
        localStorage.getItem("token")
      )
        .then((list) => {
          console.log(list.data.data);
          commit("SET_INFOPRODUCTS", list.data.data);
          return list.data.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    // async GET_TRANSFERS({commit}) {
    //     return axios('http://localhost:3000/transfers', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_TRANSFERS', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    async GET_PAYMENTS({ commit }) {
      return paynet
        .categoriesList(
          localStorage.getItem("appKey"),
          localStorage.getItem("token")
        )
        .then((products) => {
          console.log(products);
          commit("SET_PAYMENTS", products.data.data);
          return products.data.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    // async GET_BANKINFO({commit}) {
    //     return axios('http://localhost:3000/bankInfo', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_BANKINFO', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    // async GET_BANKPRODUCTS({commit}) {
    //     return axios('http://localhost:3000/bankProducts', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_BANKPRODUCTS', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    // async GET_TRANSFERTYPES({commit}) {
    //     return axios('http://localhost:3000/transferTypes', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_TRANSFERTYPES', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    // async GET_PAYMENTTYPES({commit}) {
    //     return axios('http://localhost:3000/paymentTypes', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_PAYMENTTYPES', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    // async GET_SYSTEMINFO({commit}) {
    //     return axios('http://localhost:3000/systemInfo', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_SYSTEMINFO', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    // async GET_DEPOSITS({commit}) {
    //     return axios('http://localhost:3000/deposits', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_DEPOSITS', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // },
    // async GET_CREDITS({commit}) {
    //     return axios('http://localhost:3000/credits', {
    //         method: "GET"
    //     })
    //         .then((products)=> {
    //             commit('SET_CREDITS', products.data);
    //             return products;
    //         })
    //         .catch((error)=> {
    //             console.log(error);
    //             return error;
    //         })
    // }
  },
  getters: {
    INFOPRODUCTS(state) {
      return state.infoProducts;
    },
    // TRANSFERS(state) {
    //     return state.transfers
    // },
    PAYMENTS(state) {
      return state.payments;
    },
    // BANKINFO(state) {
    //     return state.bankInfo
    // },
    // BANKPRODUCTS(state) {
    //     return state.bankProducts
    // },
    // TRANSFERTYPES(state) {
    //     return state.transferTypes
    // },
    // PAYMENTTYPES(state) {
    //     return state.paymentTypes
    // },
    // SYSTEMINFO(state) {
    //     return state.systemInfo
    // },
    // DEPOSITS(state) {
    //     return state.deposits
    // },
    // CREDITS(state) {
    //     return state.credits
    // }
  },
};

export default systemStore;
