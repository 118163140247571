// import axios from 'axios';
// import { createStore } from 'vuex'

const interfaceStore = {
    state: {
        activePage: "home",
        modals: {
            notification: false,
            user: false,
            cardList: {
                status: false,
                type: "",
                sender: "",
                recipient: "",
                recentSelect: "",
                recentSave: "",
            },
            userWidgets: false,
            search: false,
            exit: false,
            productModal: false,
            transactionModal: {
                status: false,
                transaction: {},
            },
            clarification: false,
            serverError: {
                status: false,
                title: "",
                info: "",
                button: {
                    title: "",
                    link: ""
                }
            },
        },
        productModalInfo: {
            type: "",
            id: ""
        },
        transactionModalInfo: {
            id: ""
        }
    },
    mutations: {},
    actions: {},
    getters: {},
}

export default interfaceStore;