<template>
  <header class="modal-window__header">
    <h2 class="modal-window__title">Действия с картой</h2>
    <button class="modal-window-closed" type="button" @click="closeModal">
      <svg width="20" height="20">
        <use xlink:href="#icon-close"></use>
      </svg>
    </button>
  </header>
  <div class="modal-window__content">
    <ul class="modal-window__list">
      <li class="modal-window__item">
        <router-link class="modal-window__link" :to="{name: 'card-replenishment', params:{token: $store.state.interface.productModalInfo.id}}">
          <svg width="20" height="20">
            <use xlink:href="#icon-card"></use>
          </svg>
          <span>Пополнить карту</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </router-link>
      </li>
      <li class="modal-window__item">
        <router-link class="modal-window__link" :to="{name: 'history', hash: `#${$store.state.interface.productModalInfo.id}`}">
          <svg width="20" height="20">
            <use xlink:href="#icon-chart"></use>
          </svg>
          <span>Мониторинг</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </router-link>
      </li>
      <li class="modal-window__item">
        <router-link class="modal-window__link" :to="{name: 'card-settings', params:{token: $store.state.interface.productModalInfo.id}}">
          <svg width="20" height="20">
            <use xlink:href="#icon-settings-stroke"></use>
          </svg>
          <span>Настройки карты</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </router-link>
      </li>
      <li class="modal-window__item">
        <button type="button" class="modal-window__link" @click="deleteCard">
            <svg width="20" height="20">
              <use xlink:href="#icon-trash"></use>
            </svg>
            <span>Удалить карту</span>
            <svg class="arrow" width="20" height="20">
              <use xlink:href="#icon-right"></use>
            </svg>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "g-bar-product-card",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$store.state.interface.modals.productModal = false;
    },
    deleteCard() {
        this.$store.state.interface.modals.productModal = false;
        this.$store.state.interface.modals.clarification = true;
    }
  },
  watch: {},
  created() {},
};
</script>
