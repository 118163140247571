// import axios from 'axios';
import { createStore } from 'vuex'
import interfaceStore from '@/vuex/interfaceStore'
import userStore from '@/vuex/userStore'
import systemStore from '@/vuex/systemStore'

const store = createStore({
    modules: {
        interface: interfaceStore,
        user: userStore,
        system: systemStore,
    }
})

export default store;