<template>
  <div
    class="modal-overlay"
    :class="{ open: this.$store.state.interface.modals.cardList.status }"
    @click="closeModal"
  >
    <div
      class="modal"
      :class="{ open: this.$store.state.interface.modals.cardList.status }"
      role="dialog"
      :tabindex="
        this.$store.state.interface.modals.cardList.status ? '0' : '-1'
      "
      :ariaHidden="
        this.$store.state.interface.modals.cardList.status ? 'false' : 'true'
      "
      @click.stop
      aria-hidden="true"
      aria-label="Список карт"
    >
      <header class="modal-header">
        <div class="modal-header__content">
          <svg width="20" height="20">
            <use xlink:href="#icon-card"></use>
          </svg>
          <h2>
            {{
              this.cardListStore.type == "sender"
                ? "Мои карты"
                : "Выбор получателя"
            }}
          </h2>
        </div>
        <button class="modal-closed" type="button" @click="closeModal">
          <svg width="20" height="20">
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </header>
      <div class="modal-content">
        <div class="modal-cards">
          <h3
            class="modal-cards__title"
            v-if="this.cardListStore.type != 'sender'"
          >
            Мои карты
          </h3>
          <ul
            class="modal-cards__list"
            aria-label="Список карт"
            v-if="CARDS.length"
          >
            <li
              class="modal-cards__item"
              v-for="(card, index) in CARDS"
              :key="index"
              :card="card"
            >
              <button
                class="accordion-card"
                type="button"
                @click="selectCard(card)"
                :disabled="disabled(card)"
              >
                <div class="card-miniature">
                  <div class="card-miniature__background">
                    <img
                      :src="card.theme.image"
                      :alt="card.theme.name"
                    />
                  </div>
                  <div class="card-miniature__header">
                    <span class="card-miniature__title">{{ card.name }}</span>
                  </div>
                  <!-- <span class="card-miniature__balance"
                    >{{ card.balance.toLocaleString() }} <span>UZS</span></span
                  > -->
                  <div class="card-miniature__footer">
                    <span class="card-miniature__number"
                      >**{{ lastNumbers(card.pan) }}</span
                    >
                    <span class="card-miniature__date">{{
                      expireFormat(card.expire)
                    }}</span>
                  </div>
                </div>
                <div class="accordion-card__content">
                  <span class="accordion-card__title"
                    >{{ card.name }} **{{ lastNumbers(card.pan) }}</span
                  >
                  <span class="accordion-card__balance"
                    >{{ card.balance.toLocaleString() }} <span>UZS</span></span
                  >
                </div>
              </button>
            </li>
          </ul>
          <template
            v-if="
              this.cardListStore.type != 'sender' && recentlyRecipients.length
            "
          >
            <h3 class="modal-cards__title" style="margin-top: calc(1.5 * var(--indent));">Последние получатели</h3>
            <ul class="operation-list">
              <li
                class="operation-list__item"
                v-for="(item, index) in recentlyRecipients"
                :key="index"
              >
                <button
                  class="operation-list__button"
                  type="button"
                  @click="selectRecently(item)"
                >
                  <span class="operation-list__left">
                    <span class="operation-list__img">
                      <img
                        :src="item.card_icon"
                        :alt="item.card_holder"
                      />
                    </span>
                    <span class="operation-list__info">
                      <span class="operation-list__name">{{ item.card_holder }}</span>
                      <span class="operation-list__type">{{ item.card_mask }}</span>
                    </span>
                  </span>
                </button>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import transfers from "@/api/transfers";

export default {
  name: "g-notification-modal",
  components: {},
  props: {},
  data() {
    return {
      cards: [],
      recentlyRecipients: [],
      cardListStore: this.$store.state.interface.modals.cardList,
    };
  },
  computed: {
    ...mapGetters(["CARDS"]),
  },
  methods: {
    lastNumbers(number) {
      let result = String(number).slice(-4);
      return result;
    },
    expireFormat(expire) {
      let array = expire.split("");
      array.splice(2, 0, "/");
      return array.join("");
    },
    closeModal() {
      this.cardListStore.status = false;
    },
    selectCard(card) {
      if (this.cardListStore.type == "sender") {
        this.cardListStore.sender = card;
      } else if (this.cardListStore.type == "recipient") {
        this.cardListStore.recipient = card;
      }
      this.closeModal();
    },
    selectRecently(card) {
      this.cardListStore.recipient = "";
      this.cardListStore.recentSelect = card;
      this.closeModal();
    },
    disabled(card) {
      if (
        this.cardListStore.type == "sender" &&
        card.token == this.cardListStore.recipient.token
      ) {
        return true;
      } else if (this.cardListStore.sender) {
        if (
          this.cardListStore.type == "recipient" &&
          card.token == this.cardListStore.sender.token
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  watch: {},
  mounted() {
    transfers
      .recentlyRecipients(
        localStorage.getItem("appKey"),
        localStorage.getItem("token")
      )
      .then((resp) => {
        this.recentlyRecipients = resp.data.data;
      });
  },
  updated() {
    if (this.$store.state.interface.modals.cardList.status) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  },
};
</script>
<style lang="css" scoped>
  .operation-list .operation-list__item:first-child .operation-list__button {
    padding-top: 0;
  }
</style>
