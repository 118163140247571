<template>
  <header class="modal-window__header">
    <h2 class="modal-window__title">Действия с вкладом</h2>
    <button class="modal-window-closed" type="button" @click="closeModal">
      <svg width="20" height="20">
        <use xlink:href="#icon-close"></use>
      </svg>
    </button>
  </header>
  <div class="modal-window__content">
    <ul class="modal-window__list">
      <li class="modal-window__item">
        <router-link
          class="modal-window__link"
          :to="{
            name: 'deposit-inner-open',
            params: { id: $store.state.interface.productModalInfo.id },
          }"
        >
          <svg width="20" height="20">
            <use xlink:href="#icon-deposit-02"></use>
          </svg>
          <span>О вкладе</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </router-link>
      </li>
      <li class="modal-window__item">
        <a href="./deposit-replenishment.html" class="modal-window__link">
          <svg width="20" height="20">
            <use xlink:href="#icon-plus-circle"></use>
          </svg>
          <span>Пополнить вклад</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </a>
      </li>
      <li class="modal-window__item">
        <a href="./deposit-withdrawal.html" class="modal-window__link">
          <svg width="20" height="20">
            <use xlink:href="#icon-minus-circle"></use>
          </svg>
          <span>Снять средства</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </a>
      </li>
      <li class="modal-window__item">
        <button
          class="modal-window__link"
          type="button"
          data-modal-open="deposit-close"
        >
          <svg width="20" height="20">
            <use xlink:href="#icon-deposit-term-03"></use>
          </svg>
          <span>Закрыть вклад досрочно</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </button>
      </li>
      <li class="modal-window__item">
        <a href="./deposit-info.html" class="modal-window__link">
          <svg width="20" height="20">
            <use xlink:href="#icon-info-line"></use>
          </svg>
          <span>Детальная информация</span>
          <svg class="arrow" width="20" height="20">
            <use xlink:href="#icon-right"></use>
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "g-bar-product-deposits",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$store.state.interface.modals.productModal = false;
    },
  },
  watch: {},
};
</script>
