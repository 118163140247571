<template>
    <header class="modal-window__header">
        <h2 class="modal-window__title">Действия со счетом</h2>
        <button class="modal-window-closed" type="button" @click="closeModal">
            <svg width="20" height="20">
                <use xlink:href="#icon-close"></use>
            </svg>
        </button>
    </header>
    <div class="modal-window__content">
        <ul class="modal-window__list">
            <li class="modal-window__item">
                <a href="./transfers.html" class="modal-window__link">
                    <svg width="20" height="20">
                        <use xlink:href="#icon-card"></use>
                    </svg>
                    <span>Пополнить счет</span>
                    <svg class="arrow" width="20" height="20">
                        <use xlink:href="#icon-right"></use>
                    </svg>
                </a>
            </li>
            <li class="modal-window__item">
                <a href="./accounts-settings.html" class="modal-window__link">
                    <svg width="20" height="20">
                        <use xlink:href="#icon-settings-stroke"></use>
                    </svg>
                    <span>Настройки счета</span>
                    <svg class="arrow" width="20" height="20">
                        <use xlink:href="#icon-right"></use>
                    </svg>
                </a>
            </li>
            <li class="modal-window__item">
                <a href="#" class="modal-window__link">
                    <svg width="20" height="20">
                        <use xlink:href="#icon-trash"></use>
                    </svg>
                    <span>Удалить счет</span>
                    <svg class="arrow" width="20" height="20">
                        <use xlink:href="#icon-right"></use>
                    </svg>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'g-bar-product-account',
    components: {
    },
    props: {},
    data() {
        return {

        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.$store.state.interface.modals.productModal = false;
        }
    },
    watch: {},
}
</script>