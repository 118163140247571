import "./assets/css/main.min.css";
import "./assets/js/main.js";
import auth from "@/api/auth";
document.body.classList.add("loading");

import { createApp } from "vue";
import App from "./App.vue";
import store from "./vuex/store";
import router from "./router/router";
import { initializeApp } from "firebase/app";
import firebase from "@/api/firebase"
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const app = createApp(App);

let isHTTPS = document.location.href.search('https');
if (isHTTPS != -1) {
  
  initializeApp(firebase.firebaseConfig);
  const messaging = getMessaging();
  
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
  });
  
  getToken(messaging, { vapidKey: 'BLPlGCrv2CQgv7d7AqQEpw2YzoRC3nXby__2tM5kkqF2Y15XduTFZauwYvpAEAENaVn8JgDZ4fzQmFp4KenQmdM' }).then((currentToken) => {
  if (currentToken) {
    if (localStorage.getItem('firebase-token')) {
      if (localStorage.getItem('firebase-token') == currentToken) {
        appInit();
      } else {
        firebase.update(localStorage.getItem('appKey'), currentToken).then(()=> {
          localStorage.setItem('firebase-token', currentToken)
          appInit();
        })
      }
    } else {
      localStorage.setItem('firebase-token', currentToken)
      appInit();
    }
    window.firebaseToken = currentToken
  } else {
    localStorage.setItem('firebase-token', 'token')
    appInit();
  }
  }).catch((err) => {
    console.log('main Token - false', err)
    localStorage.setItem('firebase-token', 'token')
    appInit();
  });
} else {
  localStorage.setItem('firebase-token', 'token')
    appInit();
}

function appInit() {
  app.use(store).use(() => {
    if (localStorage.getItem("token")) {
      auth
        .authMe(localStorage.getItem("token"), localStorage.getItem("appKey"))
        .then((resp) => {
          if (resp.data.identified) {
            auth
              .authIdentity(
                localStorage.getItem("token"),
                localStorage.getItem("appKey")
              )
              .then((info) => {
                store.state.user.identity = info.data;
              })
              .catch(() => {
                store.state.user.identity = false;
              });
          }
          store.state.user.info = resp.data;
          document.body.classList.remove("loading");
          store.state.user.token = localStorage.getItem("token");
          app.use(router).mount("#app");
        })
        .catch(() => {
          document.body.classList.remove("loading");
          store.state.user.token = "";
          localStorage.removeItem("token")
          localStorage.removeItem("appKey")
          if (window.location.pathname != "/") {
            window.location.pathname = "/";
          }
          app.use(router).mount("#app");
        });
    } else {
      document.body.classList.remove("loading");
      console.log("Токен отсутствует");
      store.state.user.token = false;
      app.use(router).mount("#app");
    }
  });
}
