import axios from "axios";
import auth from "@/api/auth";
import paynet from "@/api/paynet";
import cards from "@/api/cards";
import deposits from "@/api/deposits";

const userStore = {
  state: {
    token: "",
    appKey: false,
    cards: [],
    deposits: [],
    cardsBalance: 0,
    activeCard: {},
    savedProducts: [],
    userProducts: [],
    userWidgets: {},
    identified: false,
    avatar: "",
    // autoPayments: [],
    transactionsHistory: [],
    info: {},
    identity: false,
  },
  mutations: {
    SET_SAVEDPRODUCTS: (state, products) => {
      state.savedProducts = products;
    },
    SET_USERPRODUCTS: (state, products) => {
      state.userProducts = products;
    },
    SET_USERWIDGETS: (state, widgets) => {
      state.userWidgets = widgets;
    },
    // SET_AUTOPAYMENTS: (state, products) => {
    //   state.autoPayments = products;
    // },
    SET_TRANSACTIONSHISTORY: (state, transaction) => {
      state.transactionsHistory = transaction;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_APPKEY: (state, appKey) => {
      state.appKey = appKey;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_CARDS: (state, cards) => {
      state.cards = cards;
    },
    SET_OPENDEPOSITS: (state, deposits) => {
      state.deposits = deposits;
    },
    SET_CARDSBALANCE: (state, cardsBalance) => {
      state.cardsBalance = cardsBalance;
    },
  },
  actions: {
    async GET_SAVEDPRODUCTS({ commit }) {
      return paynet
          .favorites(localStorage.getItem("appKey"), localStorage.getItem("token"))
          .then((info) => {
            commit("SET_SAVEDPRODUCTS", info.data.data);
            return info.data.data
          })
          .catch((error) => {
            console.log(error);
            return {};
          });
    },
    async GET_USERPRODUCTS({ commit }) {
      return axios("http://localhost:3000/userProducts", {
        method: "GET",
      })
        .then((products) => {
          commit("SET_USERPRODUCTS", products.data);
          return products;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    async GET_USERWIDGETS({ commit }) {
      return axios("http://localhost:3000/userWidgets", {
        method: "GET",
      })
        .then((widgets) => {
          commit("SET_USERWIDGETS", widgets.data);
          return widgets;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    // async GET_AUTOPAYMENTS({ commit }) {
    //   try {
    //     const products = await axios("http://localhost:3000/userAutoPayments", {
    //       method: "GET",
    //     });
    //     commit("SET_AUTOPAYMENTS", products.data);
    //     return products;
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    async GET_TRANSACTIONSHISTORY({ commit }) {
      try {
        const transactions = await axios("http://localhost:3000/transactions", {
          method: "GET",
        });
        commit("SET_TRANSACTIONSHISTORY", transactions.data);
        return transactions;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async GET_APPKEY({ commit }) {
      if (localStorage.getItem("appKey")) {
        commit("SET_APPKEY", localStorage.getItem("appKey"));
        return localStorage.getItem("appKey");
      } else {
        return auth
          .device()
          .then((appKey) => {
            localStorage.setItem("appKey", appKey.data.appKey);
            commit("SET_APPKEY", appKey.data.appKey);
          })
          .catch((error) => {
            console.log(error);
            return false;
          });
      }
    },
    async GET_INFO({ commit }) {
      return auth
          .authMe(localStorage.getItem("token"), localStorage.getItem("appKey"))
          .then((info) => {
            commit("SET_INFO", info.data);
          })
          .catch((error) => {
            console.log(error);
            return {};
          });
    },
    async GET_CARDS({ commit }) {
      return cards
          .cardsList(localStorage.getItem("appKey"), localStorage.getItem("token"))
          .then((card) => {
            this.state.user.cardsBalance = Number(card.data.data.total_balance) / 100;
            commit("SET_CARDSBALANCE", this.state.user.cardsBalance);
            card.data.data.balances.forEach(card => {
              card.balance = Number(card.balance) / 100; 
            });
            commit("SET_CARDS", card.data.data.balances);
            return card.data.data.balances
          })
          .catch((error) => {
            console.log(error);
            return [];
          });
    },
    async GET_OPENDEPOSITS({ commit }) {
      return deposits
          .openDepositsList(localStorage.getItem("appKey"), localStorage.getItem("token"))
          .then((deposits) => {
            console.log(deposits)
            commit("SET_OPENDEPOSITS", deposits.data);
            return deposits.data
          })
          .catch((error) => {
            console.log(error);
            return [];
          });
    },
  },
  getters: {
    SAVEDPRODUCTS(state) {
      return state.savedProducts;
    },
    USERPRODUCTS(state) {
      return state.userProducts;
    },
    USERWIDGETS(state) {
      return state.userWidgets;
    },
    // AUTOPAYMENTS(state) {
    //   return state.autoPayments;
    // },
    TRANSACTIONSHISTORY(state) {
      return state.transactionsHistory;
    },
    APPKEY(state) {
      return state.appKey;
    },
    INFO(state) {
      return state.info;
    },
    CARDS(state) {
      return state.cards;
    },
    OPENDEPOSITS(state) {
      return state.deposits;
    },
  },
};

export default userStore;
