<template>
  <div
    class="modal-overlay"
    role="dialog"
    id="user-menu"
    tabindex="0"
    :class="{ open: this.$store.state.interface.modals.user }"
    @click="closeModal"
  >
    <div
      class="modal"
      :class="{ open: this.$store.state.interface.modals.user }"
      role="dialog"
      :tabindex="this.$store.state.interface.modals.user ? '0' : '-1'"
      :ariaHidden="this.$store.state.interface.modals.user ? 'false' : 'true'"
      @click.stop
      aria-hidden="true"
      aria-label="Меню уведомлений"
    >
      <header class="modal-header">
        <div class="modal-header__content">
          <div class="user-menu__info user-avatar">
              <span
                class="user-avatar__img"
                v-if="$store.state.user.info.data.avatar == null"
                :data-name="
                  $store.state.user.identity.first_name
                    ? $store.state.user.identity.first_name.split('')[0]
                    : 'П'
                "
              ></span>
              <img
                class="user-avatar__img"
                v-else
                :src="$store.state.user.info.data.avatar"
                alt="user"
              />
              <span class="user-avatar__name">{{
                $store.state.user.identity
                  ? $store.state.user.identity.first_name
                  : "Пользователь"
              }}</span>
            </div>
        </div>
        <button class="modal-closed" type="button" @click="closeModal">
          <svg width="20" height="20">
            <use xlink:href="#icon-close"></use>
          </svg>
        </button>
      </header>
      <div class="modal-content">
        <div class="user-menu">
          <button
            class="btn btn--red"
            type="button"
            @click="openModalIdentification"
            v-if="!$store.state.user.info.identified"
          >
            <svg class="big" width="20" height="20" aria-hidden="true">
              <use xlink:href="#icon-info-line"></use>
            </svg>
            <span>Неидентифицирован</span>
            <svg class="btn__arrow" width="20" height="20" aria-hidden="true">
              <use xlink:href="#icon-right"></use>
            </svg>
          </button>
          <ul class="modal-menu">
            <li class="modal-menu__item">
              <router-link class="modal-menu__link" to="/settings">
                <svg class="modal-menu__icon" width="20" height="20">
                  <use xlink:href="#icon-settings-stroke"></use>
                </svg>
                <span class="modal-menu__text">Настройки профиля</span>
                <svg class="modal-menu__arrow" width="20" height="20">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </router-link>
            </li>
            <li class="modal-menu__item">
              <router-link class="modal-menu__link" to="settings-safety">
                <svg class="modal-menu__icon" width="20" height="20">
                  <use xlink:href="#icon-shield"></use>
                </svg>
                <span class="modal-menu__text">Пароль и безопасность</span>
                <svg class="modal-menu__arrow" width="20" height="20">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </router-link>
            </li>
            <li class="modal-menu__item">
              <router-link class="modal-menu__link" to="/chat">
                <svg class="modal-menu__icon" width="20" height="20">
                  <use xlink:href="#icon-chat"></use>
                </svg>
                <span class="modal-menu__text">Служба поддержки</span>
                <svg class="modal-menu__arrow" width="20" height="20">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </router-link>
            </li>
            <li class="modal-menu__item">
              <button
                class="modal-menu__link"
                type="button"
                data-modal-open="confirm-logout"
                @click="$store.state.interface.modals.exit = true"
              >
                <svg class="modal-menu__icon" width="20" height="20">
                  <use xlink:href="#icon-logout"></use>
                </svg>
                <span class="modal-menu__text">Выйти из профиля</span>
                <svg class="modal-menu__arrow" width="20" height="20">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "g-notification-modal",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$store.state.interface.modals.user = false;
    },
    openModalIdentification() {
      this.$store.state.interface.modals.serverError.info =
        "Идентифицирование личности осуществляется через мобильное приложение.";
      this.$store.state.interface.modals.serverError.title = "Идентификация";
      this.$store.state.interface.modals.serverError.button.title =
        "Скачать приложение";
      this.$store.state.interface.modals.serverError.button.link = "/";
      this.$store.state.interface.modals.serverError.status = true;
    },
  },
  watch: {},
  updated() {
    if (this.$store.state.interface.modals.user) {
      document.body.classList.add("modal-open");
      document.querySelector("#user-menu").focus()
    } else {
      document.body.classList.remove("modal-open");
    }
  },
};
</script>
