<template>
  <aside class="bar">
    <div class="bar-info">
      <router-link class="bar-info__header" to="/my-products">
        <span class="bar-info__title">Мои продукты</span>
        <svg width="20" height="20" aria-hidden="true">
          <use xlink:href="#icon-right"></use>
        </svg>
      </router-link>
      <div class="bar-info__content">
        <div class="bar-info__balance">
          <input
            :type="balance ? 'text' : 'password'"
            :value="`${
              this.$store.state.user.cardsBalance
                ? Number(this.$store.state.user.cardsBalance).toLocaleString()
                : '0'
            } UZS`"
            readonly
          />
          <button
            type="button"
            aria-label="Скрыть баланс"
            @click="balance ? (balance = false) : (balance = true)"
          >
            <svg width="20" height="20" aria-hidden="true">
              <use xlink:href="#icon-eye" v-if="!balance"></use>
              <use xlink:href="#icon-no-eye" v-else></use>
            </svg>
          </button>
        </div>
        <p class="bar-info__description">Общий баланс по курсу ЦБ</p>
      </div>
    </div>
    <div class="bar__content">
      <details class="bar-accordion accordion" aria-label="Cards">
        <summary class="accordion-opener">
          <span class="accordion-opener__title">Карты</span>
          <svg class="accordion-opener__arrow" width="20" height="20">
            <use xlink:href="#icon-down"></use>
          </svg>
        </summary>
        <div class="accordion-inner">
          <ul class="accordion-list" v-if="CARDS && CARDS.length">
            <g-bar-cards
              v-for="(card, index) in CARDS"
              :key="index"
              :card_data="card"
              :card_balance="balance"
            />
          </ul>
          <ul class="accordion-list" v-else>
            <li class="accordion-list__item accordion-list__item--no-item">
              <router-link class="accordion-list-botton" to="/card-add">
                <svg width="20" height="20" aria-hidden="true">
                  <use xlink:href="#icon-plus"></use>
                </svg>
              </router-link>
            </li>
          </ul>
          <div class="accordion-footer">
            <RouterLink
              to="/cards"
              class="section-more"
              aria-label="Все продукты"
            >
              <span>Все</span>
              <svg width="20" height="20" aria-hidden="true">
                <use xlink:href="#icon-right"></use>
              </svg>
            </RouterLink>
          </div>
        </div>
      </details>
      <details class="bar-accordion accordion" aria-label="Deposits">
        <summary class="accordion-opener">
          <span class="accordion-opener__title">Вклады</span>
          <svg class="accordion-opener__arrow" width="20" height="20">
            <use xlink:href="#icon-down"></use>
          </svg>
        </summary>
        <div class="accordion-inner">
          <ul class="accordion-list" v-if="OPENDEPOSITS && OPENDEPOSITS.length">
            <gBarDeposits
              v-for="(deposits, index) in OPENDEPOSITS"
              :key="index"
              :card_data="deposits"
              />
          </ul>
          <ul class="accordion-list" v-else>
            <li class="accordion-list__item accordion-list__item--no-item">
              <router-link class="accordion-list-botton" to="/deposits">
                <svg width="20" height="20" aria-hidden="true">
                  <use xlink:href="#icon-plus"></use>
                </svg>
              </router-link>
            </li>
          </ul>
          <div class="accordion-footer">
            <RouterLink
              to="/deposits"
              class="section-more"
              aria-label="Все продукты"
            >
              <span>Все</span>
              <svg width="20" height="20" aria-hidden="true">
                <use xlink:href="#icon-right"></use>
              </svg>
            </RouterLink>
          </div>
        </div>
      </details>
      <div class="bar__footer">
        <!-- <button class="btn" @click="openWidgets">
          <svg width="20" height="20" aria-hidden="true">
            <use xlink:href="#icon-plus"></use>
          </svg>
          <span>Новый продукт</span>
        </button> -->
        <router-link class="btn" to="/products">
          <svg width="20" height="20" aria-hidden="true">
            <use xlink:href="#icon-plus"></use>
          </svg>
          <span>Новый продукт</span>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
// import gBarProducts from "@/components/bar-components/g-bar-products.vue";
import gBarCards from "@/components/bar-components/g-bar-cards.vue";
import gBarDeposits from "@/components/bar-components/g-bar-deposits.vue";
// import cards from "@/api/cards";
import { mapGetters } from "vuex";

export default {
  name: "g-bar",
  components: {
    gBarCards,
    gBarDeposits
  },
  props: {},
  data() {
    return {
      balance: true,
    };
  },
  computed: {
    ...mapGetters(["CARDS", "OPENDEPOSITS"]),
  },
  methods: {
    openWidgets() {
      this.$store.state.interface.modals.userWidgets = true;
    },
  },
  watch: {},
  created() {},
  mounted() {},
};
</script>
