import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("../pages/g-index.vue"),
    },
    {
      path: "/payments",
      name: "payments",
      component: () => import("../pages/g-payments.vue"),
    },
    {
      path: "/payment-inner/:id",
      name: "payment-inner",
      component: () => import("../pages/g-payment-inner.vue"),
    },
    {
      path: "/payment-services",
      name: "payment-services",
      component: () => import("../pages/g-payment-services.vue"),
    },
    {
      path: "/payment-services/:id",
      name: "payment-services-inner",
      component: () => import("../pages/g-payment-services-inner.vue"),
    },
    {
      path: "/cards",
      name: "cards",
      component: () => import("../pages/g-cards.vue"),
    },
    {
      path: "/card-settings/:token",
      name: "card-settings",
      component: () => import("../pages/g-card-settings.vue"),
    },
    {
      path: "/card-replenishment/:token",
      name: "card-replenishment",
      component: () => import("../pages/g-card-replenishment.vue"),
    },
    {
      path: "/story-inner/:id",
      name: "story-inner",
      component: () => import("../pages/g-story-inner.vue"),
    },
    {
      path: "/deposits",
      name: "deposits",
      component: () => import("../pages/g-deposits.vue"),
    },
    {
      path: "/deposits/:id",
      name: "deposit-inner",
      component: () => import("../pages/g-deposit-inner.vue"),
    },
    {
      path: "/deposits-open/:id",
      name: "deposit-inner-open",
      component: () => import("../pages/g-deposit-inner-open.vue"),
    },
    {
      path: "/deposit-calculator/:id",
      name: "deposit-calculator",
      component: () => import("../pages/g-deposit-calculator.vue"),
    },
    {
      path: "/deposit-add/:id",
      name: "deposit-add",
      component: () => import("../pages/g-deposit-add.vue"),
    },
    {
      path: "/credits",
      name: "credits",
      component: () => import("../pages/g-credits.vue"),
    },
    {
      path: "/card-add",
      name: "card-add",
      component: () => import("../pages/g-card-add.vue"),
    },
    {
      path: "/history",
      name: "history",
      component: () => import("../pages/g-history.vue"),
    },
    {
      path: "/showcase",
      name: "showcase",
      component: () => import("../pages/g-showcase.vue"),
    },
    {
      path: "/my-products",
      name: "my-products",
      component: () => import("../pages/g-my-products.vue"),
    },
    {
      path: "/products",
      name: "products",
      component: () => import("../pages/g-products.vue"),
    },
    {
      path: "/transfers/:id",
      name: "transfers",
      component: () => import("../pages/g-transfers.vue"),
    },
    { path: "/settings-safety",
      name: "settings-safety", 
      component: () => import("../pages/g-settings-safety.vue") ,
    },
    { path: "/settings",
      name: "settings", 
      component: () => import("../pages/g-settings.vue") ,
    },
    { path: "/chat",
      name: "chat", 
      component: () => import("../pages/g-chat.vue") ,
    },
    { path: "/payments-favorites", 
      name: "payments-favorites",
      component: () => import("../pages/g-payments-favorites.vue") ,
    },
    {
      path: "/payments-favorites/:id",
      name: "payment-favorites-services",
      component: () => import("../pages/g-payment-favorites-services.vue"),
    },
    {
      path: "/payment-favorites-inner/:id",
      name: "payment-favorites-inner",
      component: () => import("../pages/g-payment-favorites-inner.vue"),
    },
    {
      path: "/payment-favorites-inner/:id/:favorite",
      name: "payment-favorites-inner-saved",
      component: () => import("../pages/g-payment-favorites-inner.vue"),
    },
    { path: "/:pathMatch(.*)*", 
      component: () => import("../pages/g-404.vue") ,
    },
  ],
});

export default router;
