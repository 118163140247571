<template>
    <g-notification/>
    <g-user/>
    <g-user-widgets/>
    <g-card-list/>
    <g-bar-product-modal/>
    <g-transactions-modal/>
    <g-clarification-modal/>
    <g-error/>
    <g-exit-modal/>
</template>
<script>
import gNotification from "@/components/modals/g-notification.vue"
import gUser from "@/components/modals/g-user-menu.vue"
import gCardList from "@/components/modals/g-card-list.vue"
import gUserWidgets from "@/components/modals/g-user-widgets.vue"
import gBarProductModal from '@/components/modals/g-bar-product-modal.vue'
import gTransactionsModal from "@/components/modals/g-transactions-modal.vue"
import gClarificationModal from "@/components/modals/g-clarification.vue"
import gError from "./g-error.vue"
import gExitModal from "./g-exit-modal.vue"

export default {
    name: 'g-modal-index',
    components: {
        gNotification,
        gUser,
        gCardList,
        gUserWidgets,
        gBarProductModal,
        gTransactionsModal,
        gClarificationModal,
        gError,
        gExitModal
    },
    props: {},
    data() {
        return {
            
        }
    },
    computed: {},
    methods: {},
    watch: {},
    mounted() {}
}
</script>