import api from "./api";
import config from "./config";

const depositsList = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.depositsList);
};

const openDepositsList = (AppKey, token) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.openDepositsList);
};

const depositInner = (AppKey, token, id) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(`${config.depositsList}/${id}`);
};

const depositCalc = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.depositsCalc, body);
};

const depositOpen = (AppKey, token, body) => {
  api.defaults.headers.AppKey = AppKey;
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.depositOpen, body);
};

export default {
    depositsList,
    openDepositsList,
    depositInner,
    depositCalc,
    depositOpen
};
